import React from 'react'
import CardFixed from '../../../components/card/cardFixed'

export default function TeamsTournamentCard() {
  return (
    <CardFixed Title={"Times inscritos"} className="row-span-4 col-start-4  h-[full] w-full">

    </CardFixed>
  )
}
