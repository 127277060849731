import React from 'react'
import CardFixed from '../../../components/card/cardFixed'

export default function StatusTournamentCard({ tournament }) {
  return (
    <CardFixed Title={"Status"} className="col-start-3 h-[150px] w-full">
      
    </CardFixed>
  )
}
