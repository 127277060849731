import React from 'react'
import CardFixed from '../../../components/card/cardFixed'

export default function CheckinTournamentCard() {
  return (
    <CardFixed Title={"Check-In"} className="col-start-2 row-start-2 h-[150px] w-full">

    </CardFixed>
  )
}
