import CardBackgroundPage from "../../components/card/cardBackgroundPage";

export default function TeamPage() {
  return (
   <CardBackgroundPage>
      <p>Teste</p>
      <p>Teste</p>
      <p>Teste</p>
      <p>Teste</p>
   </CardBackgroundPage>
  )
}
